import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';
import { phone } from '../../../../styles/media';

const getStyles = (theme: Theme) => {
  return css`
    .Carousel {
      position: relative;
    }

    .Carousel__itemsWrapper {
      display: flex;
      width: 100%;
      scroll-behavior: smooth;
      overflow-x: hidden;
    }

    .Carousel_arrow {
      position: absolute;
      padding: 0 20px;
      width: 80px;
      height: 51px;
      background: transparent;
      border: none;
      outline: none;
      font-size: 10px;
      line-height: 1;
      cursor: pointer;
      display: flex;
      align-items: center;
      color: ${theme.base.color.dark}
    }

    .Carousel_arrow:hover {
      font-weight: 600;
      color: ${theme.button.ghost.colorHover}
    }

    .Carousel_arrow__left {
      left: 0;
      top: 0;
      background-image: linear-gradient(to right,  ${theme.base.color.white}, ${theme.base.color.white}, transparent);
      justify-content: flex-start;
    }

    .Carousel_arrow__right {
      right: 0;
      top: 0;
      background-image: linear-gradient(to left, ${theme.base.color.white}, ${theme.base.color.white}, transparent);
      justify-content: flex-end;
    }

    @media ${phone} {
      .Carousel__itemsWrapper {
        overflow-x: scroll;
      }

      .Carousel_arrow {
        display: none;
      }
    }
  `;
};

export default getStyles;
