import Image from "next/image";
import { LocalizeFunc } from "src/services/localize/useLocalizeService";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { Typography } from "src/ui-kit/Typography";

interface SearchBoxNotFoundProps {
  searchString: string;
  localize: LocalizeFunc;
}

const SearchBoxNotFound = (props: SearchBoxNotFoundProps) => {
  const { searchString, localize } = props;

  return (
    <StylishBox
      data-marker="No Results"
      sbs={{
        height: '225px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        p: '10px',
        textAlign: 'center',
      }}
    >
      <Image
        width={150}
        height={67}
        src="/static/images/donut.png"
        alt="Donut"
      />
      <Typography
        variant="text6"
        sbs={{
          mt: '25px',
          lineHeight: '17px',
        }}
      >
        {localize('search.result.nothing-found')}
        <strong>{` «${searchString}»`}</strong>
      </Typography>
    </StylishBox>
  );
};

export default SearchBoxNotFound;
