import { getDataFromResponse } from "src/utils/api/getDataFromResponse";
import useSWR from "swr";
import { fetcher } from "../../../api/fetch";
import { UberProductsResponseDto } from "../../../api/types";
import { MIN_SEARCH_LENGTH } from "../../../data/constants";

export interface SearchCategoryResultDto {
  slug: string;
  name: string;
}

export interface SearchResponseDto extends UberProductsResponseDto {
  category_results: SearchCategoryResultDto[];
  fuzzy: boolean;
}

const emptyArray = [];

interface SearchParams {
  searchValue: string;
  language: string;
  perPage?: number;
  page?: number;
  sort_by?: string;
}

const swrFetcher = async ([url, language]: string[]) => {
  const res = await fetcher<SearchResponseDto>(url, { language });
  return getDataFromResponse(res, {} as SearchResponseDto) ;
};

const useSearch = (params: SearchParams) => {
  const {
    searchValue,
    language,
    perPage = 10,
    page,
    sort_by,
  } = params;
  const q = encodeURIComponent(searchValue);

  const validSearchValue = searchValue.length >= MIN_SEARCH_LENGTH;
  const sortByQuery = sort_by ? `&sort=${sort_by}` : '&sort=relevance_desc';
  const pageQuery = page ? `&page=${page}` : '';
  const perPageQuery =`&per_page=${perPage}`;

  const url = validSearchValue
    ? `/uber_catalog/products/search/?q=${q}${perPageQuery}${pageQuery}${sortByQuery}`
    : null;

  const {
    data,
    error: swrError,
  } = useSWR<SearchResponseDto>(
    validSearchValue ? [url, language] : null,
    swrFetcher,
  );

  const fetching = validSearchValue && !data && !swrError;
  const products = (data && data.results) || emptyArray;
  const count = (data && data.count_available) || 0;
  const categories = (data && data.category_results) || emptyArray;
  const notFound = validSearchValue && !!data && !products.length && !categories.length;

  return {
    fetching,
    products,
    count,
    categories,
    notFound,
  };
};

export default useSearch;
