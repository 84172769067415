import cn from 'classnames';
import { Fragment, InputHTMLAttributes, KeyboardEventHandler, useEffect, useRef, useState } from "react";
import { sendCategoryClick } from 'src/modules/ubercatalog';
import useLang from 'src/services/language/useLang';
import useLocalize from 'src/services/localize/useLocalize';
import { useTheme } from 'src/ui-kit/theme';
import { IS_IOS, MIN_SEARCH_LENGTH } from "../../../../data/constants";
import { MIN_TABLET_WIDTH } from "../../../../styles/media";
import IconFont from '../../../../ui-kit/IconFont';
import Spinner from "../../../ubercatalog/components/Spinner/Spinner";
import { Place } from '../../helpers/analytics';
import { SearchCategoryResultDto } from '../../hooks/useSearch';
import SearchInput, { SearchBoxViewVariant, SearchInputBaseProps } from '../SearchInput/SearchInput';
import getStyles from "./SearchBox.styles";
import SearchBoxNotFound from './SearchBoxNotFound';
import SearchBoxOverlay from './SearchBoxOverlay';
import SearchResults from "./SearchResults/SearchResults";
import { SearchResultProduct } from './useSearchBox';


export interface SearchBoxProps {
  isFetching: boolean;
  isNotFounded: boolean;
  searchString: string;
  products: SearchResultProduct[];
  count: number;
  inputProps: SearchInputBaseProps;
  searchBoxIsActive: boolean;
  categories: SearchCategoryResultDto[];
  variant: SearchBoxViewVariant;
  disableSearchBox(): void;
  clearSearch(): void;
  clearAndCloseSearchBox(): void;
  openSearchPage(value: string): void;
  onClose?: () => void;
  place: Place;
}

const SearchBox = (props: SearchBoxProps) => {
  const theme = useTheme();
  const localize = useLocalize();
  const styles = getStyles(theme);
  const searchResultsRef = useRef<HTMLDivElement>(null);
  const lang = useLang();
  const [resultsHeight, setResultsHeight] = useState(225);

  const {
    place,
    variant,
    onClose,
    searchBoxIsActive,
    inputProps,
    isFetching,
    products,
    count,
    categories,
    isNotFounded,
    searchString,
    disableSearchBox,
    clearSearch,
    clearAndCloseSearchBox,
    openSearchPage,
  } = props;

  const isMobileView = variant === 'mobile';

  const inputValue = inputProps.value;
  const searchInputIsFilled = inputValue.length >= MIN_SEARCH_LENGTH;

  const specificAttributes: InputHTMLAttributes<HTMLInputElement> = {};
  if (IS_IOS) specificAttributes.inputMode = 'search';

  useEffect(() => {
    if (window.innerWidth < MIN_TABLET_WIDTH) {
      setResultsHeight(140);
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth >= MIN_TABLET_WIDTH && (products.length || isNotFounded)) {
      const results = searchResultsRef.current;
      const resultsHeight = results ? results.clientHeight : 225;
      setResultsHeight(resultsHeight);
    }
  }, [products, isNotFounded]);

  const handlePressEnter = () => {
    if (inputValue && searchInputIsFilled) {
      openSearchPage(inputValue);

      handleClose();
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = event => {
    if (event.key === 'Enter') {
      handlePressEnter();
    }
  };

  const handleClose = () => {
    if(typeof onClose === 'function') {
      onClose();
    }
  };

  const handleOnProductClick = () => {
    clearAndCloseSearchBox();
    handleClose();
  };

  const handleOnCategoryClick = (slug: string) => {
    sendCategoryClick({
      page: place,
      place: 'search_category',
      category_id: slug,
    });

    clearAndCloseSearchBox();
    handleClose();
  };

  const handleBackClick = () => {
    disableSearchBox();
    handleClose();
  };

  const handleViewAllClick = () => {
    openSearchPage(String(inputValue));
    handleClose();
  };

  const overlayIsVisible = isMobileView || searchBoxIsActive;

  return (
    <div
      className={`SearchBox SearchBox_${variant}`}
      data-testid="searchBox"
      data-marker="Search Box"
      onMouseDown={e => e.stopPropagation()}
      onKeyDown={handleKeyDown}
    >
      <SearchInput {...inputProps} variant={variant}  />

      {overlayIsVisible &&
        <Fragment>
          <SearchBoxOverlay
            variant={variant}
            onClick={clearAndCloseSearchBox}
          />
          <span
            className="SearchBox__icon SearchBox__back"
            data-marker="Search Back"
            onClick={handleBackClick}
          >
            <IconFont name='arrow-back' />
          </span>
        </Fragment>
      }

      <div className={cn('SearchBox__icons', {
          'SearchBox__icons_withMobile': isMobileView,
        })}
      >
        {inputValue && (
          <div
            className={cn('SearchBox__icon SearchBox__clearSearch',
            {
              'SearchBox__clearSearch_hidden': !searchBoxIsActive,
            })}
            data-testid="clear-search-button"
            data-marker="Clear search"
            onClick={clearSearch}
            role="button"
            tabIndex={0}
          >
            <IconFont name='close' />
          </div>
        )}

        <div
          onClick={handlePressEnter}
          className={cn('SearchBox__icon', {
            'SearchBox__loupeIcon_active': searchInputIsFilled,
            'SearchBox__loupeIcon_hidden': inputValue && !searchBoxIsActive ,
          })}
          data-testid="loupe"
          data-marker="Loupe"
          role="button"
          tabIndex={0}
        >
          <IconFont name='loupe' />
        </div>
      </div>
      { searchBoxIsActive && searchInputIsFilled &&
        <div
          className="SearchBox__resultsContainer"
          ref={searchResultsRef}
          data-marker="Search Results"
          onTouchStart={() => inputProps.ref.current?.blur()}
        >
          {isFetching && (
            <div className="SearchBox__spinner" style={{ height: resultsHeight }}>
              <Spinner />
            </div>
          )}

          {isNotFounded && (
            <SearchBoxNotFound
              searchString={searchString}
              localize={localize}
            />
          )}

          { (!!products.length || !!categories.length) && (
            <div className="SearchBox__results">
              <SearchResults
                place={place}
                products={products}
                count={count}
                categories={categories}
                language={lang}
                searchString={searchString}
                onViewAllClick={handleViewAllClick}
                onCategoryClick={handleOnCategoryClick}
                onProductClick={handleOnProductClick}
              />
            </div>
          )}
        </div>
      }
      <style jsx>{styles}</style>
    </div>
  );
};

export default SearchBox;
