import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';
import { phone, tablet } from '../../../../styles/media';
import typography from '../../../../styles/typography';

const getStyles = (theme: Theme) => {
  return css`
    .ProductRow {
      padding: 12px 20px;
      border-bottom: 1px solid ${theme.base.color.gray100};
      display: flex;
      cursor: pointer;
    }

    .ProductRow:last-of-type {
      border-bottom: none;
    }

    .ProductRow__imageWrap {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ProductRow__image {
      max-width: 100%;
      max-height: 100%;
    }

    .ProductRow__info {
      display: flex;
      width: 100%;
    }

    .ProductRow__description {
      display: flex;
      flex-direction: column;
      width: 235px;
      padding-left: 12px;
      ${typography.h9}
      line-height: 15px;
    }

    .ProductRow__title {
      margin-bottom: 4px;
      text-decoration: none;
    }

    .ProductRow__title:hover {
      color: ${theme.base.color.primary};
    }

    .ProductRow__infoWeight {
      color: ${theme.base.color.gray500};
    }

    .ProductRow__priceAndButton {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .ProductRow__price {
      margin-left: 16px;
    }

    .ProductRow__unavailable {
      ${typography.notAccentedH9}
    }

    @media ${tablet} {
      .ProductRow {
        padding-right: 32px;
        padding-left: 32px;
      }
    }

    @media ${phone} {
      .ProductRow {
        width: 100%;
        padding: 13px 10px;
      }

      .ProductRow__info {
        flex-direction: column;
      }

      .ProductRow__price {
        margin-left: 12px;
      }

      .ProductRow__description {
        align-items: flex-start;
        width: 100%;
        text-align: left;
      }

      .ProductRow__priceAndButton {
        justify-content: flex-start;
        margin-top: 12px;
      }
    }
  `;
};

export default getStyles;
