export type Place = 'catalog' | 'landing';

export const sendOpenSearch = (place: Place) => {
  window.dataLayer.push({
    event: "search_open",
    place,
  });
};

interface SendSearchStartParams {
  place: Place;
  searchString: string;
}

export const sendSearchStart = (params: SendSearchStartParams) => {
  const { place, searchString } = params;

  window.dataLayer.push({
    event: "search_start",
    search_term : searchString,
    place,
  });
};

interface SendSearchFullParams {
  place: Place;
  searchString: string;
  searchResultCount: number;
}

export const sendSearchFull = (params: SendSearchFullParams) => {
  const { searchResultCount, place, searchString } = params;

  window.dataLayer.push({
    event: "search_full",
    search_term : searchString,
    search_result: searchResultCount,
    place,
  });
};
