import { phone_tablet } from 'src/styles/media';
import typography from 'src/styles/typography';
import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';

export const getStyles = (theme: Theme) => {
  return css`
    .SearchInput {
      display: block;
      width: 100%;
      height: 40px;
      outline: 0 none;
      -webkit-appearance: none;
      padding: 10px 78px 10px 16px;
      border-radius: 3px;
      border: 1px solid ${theme.base.color.gray300};
      background: ${theme.base.color.white};
      color: ${theme.base.color.dark};
      position: relative;
      z-index: 4;
    }

    .SearchInput:focus {
      border-color: ${theme.base.color.gray500};
      outline: none;
    }

      /* clears the 'X' from Internet Explorer */
    .SearchInput::-ms-clear {  display: none; width : 0; height: 0; }
    .SearchInput::-ms-reveal {  display: none; width : 0; height: 0; }

    /* clears the 'X' from Chrome */
    .SearchInput::-webkit-search-decoration,
    .SearchInput::-webkit-search-cancel-button,
    .SearchInput::-webkit-search-results-button,
    .SearchInput::-webkit-search-results-decoration {
      -webkit-appearance:none;
    }

    .SearchInput::placeholder {
      font-weight: 400;
      color: ${theme.base.color.gray500};
    }

    @media ${phone_tablet} {
      .SearchInput_withMobile {
        ${typography.h5}
        outline: 0 none;
        -webkit-appearance: none;
        height: 56px;
        padding: 16px 88px 16px 48px;
      }
    }
  `;
};
