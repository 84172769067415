import { Fragment } from "react";
import { useTheme } from "src/ui-kit/theme";
import { SearchBoxViewVariant } from "../SearchInput/SearchInput";
import getStyles from './SearchBoxOverlay.styles';

interface Props {
  onClick(): void;
  variant: SearchBoxViewVariant;
}

const SearchBoxOverlay = ({ onClick, variant }: Props) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Fragment>
      <div
        className={`SearchBoxOverlay SearchBoxOverlay_${variant}`}
        onClick={onClick}
        data-testid="overlay"
        data-marker="Overlay"
      />
      <style jsx>{styles}</style>
    </Fragment>
  );
};

export default SearchBoxOverlay;
