import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';
import { desktop, phone_tablet } from '../../../../styles/media';

const getStyles = (theme: Theme) => {
  return css`
    .SearchBox {
      width: 100%;
      position: relative;
    }

    .SearchBox_headerDesktop {
      display: none;
    }

    .SearchBox_mobile {
      display: block;
    }

    .SearchBox__clearSearch {
      cursor: pointer;
      margin-right: 12px;
    }

    .SearchBox__icons {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 16px;
      z-index: 4;
      height: 40px;
    }

    .SearchBox__icon {
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      color: ${theme.base.color.gray400};
      width: 20px;
      height: 20px;
      text-decoration: none;
      outline: none;
    }

    .SearchBox__back {
      display: none;
      position: absolute;
      left: 16px;
      top: 18px;
      z-index: 5;
      font-size: 18px;
      cursor: pointer;
    }

    .SearchBox_mobile .SearchBox__back {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .SearchBox__clearSearch {
      margin-right: 8px;
      font-size: 11px;
    }

    .SearchBox__icon:hover {
      color: ${theme.base.color.dark};
    }

    .SearchBox__loupeIcon_active {
      cursor: pointer;
    }

    .SearchBox__spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .SearchBox__resultsContainer {
      position: absolute;
      z-index: 4;
      display: flex;
      flex-direction: column;
      background-color: ${theme.base.color.white};
      overflow: auto;
      max-height: 100vh;
      width: 100%;
    }

    .SearchBox_headerDesktop .SearchBox__resultsContainer {
      max-height: calc(100vh - 150px);
    }

    .SearchBox_mainPage .SearchBox__resultsContainer {
      max-height: calc(100vh - 195px);
    }

    .SearchBox__results {
      height: 100%;
    }

    @media ${desktop} {
      .SearchBox_headerDesktop {
        display: block;
      }

      .SearchBox__resultsContainer {
        width: 100%;
        margin-top: 20px;
      }

      .SearchBox_mainPage .SearchBox__resultsContainer {
        margin-bottom: 20px;
      }
    }

    @media ${phone_tablet} {
      .SearchBox__clearSearch {
        margin-right: 8px;
      }
      .SearchBox__resultsContainer {
        position: absolute;
        top: 56px;
        left: 0;
        bottom: 0;
        right: 0;
        max-height: 100%;
      }

      .SearchBox_mainPage .SearchBox__resultsContainer {
        max-height: calc(100vh - 170px);
      }
    }

    @media ${phone_tablet} {
      .SearchBox_mobile {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
      }
      .SearchBox__results {
        height: 100%;
      }

      .SearchBox__icons_withMobile {
        height: 56px;
      }
      .SearchBox__clearSearch_hidden::after ,
      .SearchBox__loupeIcon_hidden{
        display: none;
      }
    }
  `;
};

export default getStyles;
