import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';
import { phone_tablet } from '../../../../styles/media';

const getStyles = (theme: Theme) => {
  return css`
    .SearchBoxOverlay {
      position: fixed;
      z-index: 3;
      top: 64px;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${theme.base.color.dark};
      opacity: 0.45;
    }

    @media ${phone_tablet} {
      .SearchBoxOverlay_mobile {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: -1;
        background: ${theme.base.color.white};
        opacity: 1;
      }

    }
  `;
};

export default getStyles;
