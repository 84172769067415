import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from 'src/ui-kit/theme';
import IconFont from '../../../../ui-kit/IconFont';
import getStyles from './Carousel.styles';

interface Props {
  children: React.ReactElement[];
  selected?: number;
  scrollStep?: number;
  autoScroll?: boolean;
  dataMarker?: string;
}

const Carousel = (props: Props) => {
  const {
    children,
    selected = 0,
    scrollStep = 400,
    autoScroll,
    dataMarker = 'Carousel',
  } = props;

  const theme = useTheme();
  const styles = getStyles(theme);
  const carouselRef = useRef<HTMLDivElement>(null);

  const [position, setPosition] = useState(0);
  const [maxPosition, setMaxPosition] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const handleLeftArrowClick = () => {
    const newPosition = position - scrollStep < 0
      ? 0
      : (position - scrollStep);

    setPosition(newPosition);
  };

  const handleRightArrowClick = () => {
    const newPosition = position + scrollStep > maxPosition
      ? maxPosition
      : (position + scrollStep);

    setPosition(newPosition);
  };

  useEffect(() => {
    const carousel = carouselRef.current;
    carousel?.scrollTo(position, 0);

    const leftArrowShouldBeVisible = position !== 0;
    setShowLeftArrow(leftArrowShouldBeVisible);

    const rightArrowShouldBeVisible = position !== maxPosition;
    setShowRightArrow(rightArrowShouldBeVisible);

  }, [position, maxPosition]);

  useEffect(() => {
    const carousel = carouselRef.current as HTMLDivElement;
    const maxPosition = carousel.scrollWidth - carousel.clientWidth;
    setMaxPosition(maxPosition);
  }, [children]);

  useEffect(() => {
    if (!autoScroll) {
      return;
    }

    if (selected === 0) {
      setPosition(0);

      return;
    }

    const carousel = carouselRef.current as HTMLDivElement;
    const selectedElement = carousel.children[selected] as HTMLDivElement;

    if ( selectedElement && showRightArrow
      && selectedElement.offsetLeft + selectedElement.offsetWidth - position > carousel.clientWidth - 60
    ) {
      handleRightArrowClick();
    }

    if ( selectedElement && showLeftArrow
      && selectedElement.offsetLeft < position + 60
    ) {
      handleLeftArrowClick();
    }
  /** @todo use dependencies */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <div className='Carousel' data-marker={dataMarker}>
      <div
        className='Carousel__itemsWrapper'
        ref={carouselRef}
        data-testid='carousel'
      >
        {children}
      </div>

      { showLeftArrow &&
        <button
          className='Carousel_arrow Carousel_arrow__left'
          onClick={handleLeftArrowClick}
          data-testid='leftArrow'
          data-marker='Left Arrow'
        >
          <IconFont name='arrow-small-left' />
        </button>
      }

      { showRightArrow &&
        <button
          className='Carousel_arrow Carousel_arrow__right'
          onClick={handleRightArrowClick}
          data-testid='rightArrow'
          data-marker='Right Arrow'
        >
          <IconFont name='arrow-small-right' />
        </button>
      }
      <style jsx>{styles}</style>
    </div>
  );
};

export default Carousel;
